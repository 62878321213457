/**
 * This GA event datalayer is pushed when user clicks on a product from personal recommendation floor throughout the website such as homePage, categoryPage, productPage, cartPage
 *
 * @param {string} title - The title or the floor name of the perso-reco floor
 */

import { getCookie } from '@/src/Utils/Cookies';
import { decrypt } from '@/src/Middleware/helpers';

export const GAEvent_voiceSearchIntitate = (title: string) => {
  try {
    const location = window?.location?.href;
    const loyaltyId = decrypt(getCookie('dkt_loyaltyCard')) || null;

    window.dataLayer.push({
      event: 'voice_search_intitate',
      location: location || 'N/A',
      loyalty_id: loyaltyId,
    });
  } catch {}
};

export const GAEvent_voiceSearchSubmit = (search_term: string) => {
  try {
    const location = window?.location?.href;
    const loyaltyId = decrypt(getCookie('dkt_loyaltyCard')) || null;

    window.dataLayer.push({
      event: 'voice_search_submit',
      location: location || 'N/A',
      loyalty_id: loyaltyId,
      search_term: search_term,
    });
  } catch {}
};

export const GAEvent_voiceSearchError = (search_term: string, error_message: string) => {
  try {
    const location = window?.location?.href;
    const loyaltyId = decrypt(getCookie('dkt_loyaltyCard')) || null;

    window.dataLayer.push({
      event: 'voice_search_error',
      location: location || 'N/A',
      loyalty_id: loyaltyId,
      search_term: search_term,
      error_message: error_message,
    });
  } catch {}
};

/**
 *This Amplitude event is triggered when a user searches for specific items.
 *
 * @param {string} search_term - The product or category that the user searched for.
 * @param {number} noOfHits - The number of products returned by the search query.
 */

export const amplitude_searchEvent = (search_term: string, noOfHits: number) => {
  try {
    window.dataLayer.push({
      event: 'Search Completed',
      'search term': search_term,
      'number search results': noOfHits,
    });
  } catch {}
};

/**
 * This is an amplitude when the search bar is opened.
 */

export const amplitude_searchBarOpened = () => {
  try {
    window.dataLayer.push({
      event: 'Search Bar Opened',
    });
  } catch {}
};

/**
 * Triggers an Amplitude event when a user selects a recently searched suggestion.
 *
 * @param {string} searchTerm - The term that the user selected from the recent search suggestions.
 */

export const amplitude_postSearchLinkSelected = (searchTerm: string) => {
  try {
    window.dataLayer.push({
      event: 'Post Search Link Selected',
      'search link type': 'search term',
      'search term': searchTerm,
    });
  } catch {}
};

/**
 * Triggers an Amplitude event when a user searches for a product and clicks on a suggested 'Collection' or the 'Explore all products that matches the search term' link. *
 *
 * @param {string} searchTerm - The name of the collection that the user clicks on.
 */

export const amplitude_preSearchLinkSelected = (searchTerm: string, searchLinkType: string) => {
  try {
    window.dataLayer.push({
      event: 'Pre Search Link Selected',
      'search link type': searchLinkType,
      'search term': searchTerm,
    });
  } catch {}
};

/**
 * Triggers an Amplitude event when a user searches for a product and clicks on a suggested 'Top products' item.*
 *
 * @param {string} searchTerm - The name of the product that the user clicks on.
 */

export const internalSearchRedirected = (searchTerm: string, searchLinkType: string) => {
  try {
    window.dataLayer.push({
      event: 'Internal Search Redirected',
      'search link type': searchLinkType,
      'destination page type': 'Product Page',
      'destination page': searchTerm,
    });
  } catch {}
};

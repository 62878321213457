// import ProductImp from './ProductImpression';

let ProductIdList2 = [];
const ProductIdListHome = [];
const ProductIdListAlgolia = [];
const ProductIdListCategory = [];

let count = 0;
let pImp;
const DetectVisible = (productInfo) => {
  if (productInfo) {
    pImp = productInfo;
  }

  if (typeof document !== 'undefined') {
    const images = document.querySelectorAll('.card');
    if ('IntersectionObserver' in window) {
      // IntersectionObserver Supported
      const config = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      };
      const observer = new IntersectionObserver(onChange, config);
      images.forEach((img) => observer.observe(img));

      function onChange(changes, observer) {
        ProductIdList2 = [];
        count = 0;
        changes.forEach((change) => {
          if (change.intersectionRatio > 0) {
            // Stop watching and load the image
            loadImage(change.target);
            observer.unobserve(change.target);
          }
        });
        if (ProductIdList2.length === count && ProductIdList2.length && count > 0) {
          const pArr = pImp && pImp.productInfo;
          if (pArr && pArr.length > 0 && ProductIdList2.length > 0) {
            // ProductImp(pArr, ProductIdList2);
          }
        }
      }
    } else {
      // IntersectionObserver NOT Supported
      images.forEach((image) => loadImage(image));
    }
  }

  // DSM click Imp
  function dsmClick(pArr, dsmId) {
    if (pArr && dsmId) {
      // ProductImp(pArr, dsmId);
    }
  }
  function loadImage(image) {
    const modelId = image.querySelector('.algolia-clicks')?.id;
    // Onscroll visible products Home page
    if (window.location.pathname === '/' && window.location.href.indexOf('query') === -1) {
      if (image.classList.contains('fade-in') === false && !ProductIdListHome.includes(modelId)) {
        ProductIdList2.push(modelId);
        ProductIdListHome.push(modelId);
        image.classList.add('fade-in');
        count += 1;
      }
      // multiColor DSM click
      else if (!ProductIdListHome.includes(modelId)) {
        ProductIdListHome.push(modelId);
        dsmClick(pImp, modelId);
      }
    } else if (window.location.href.indexOf('query') > 1) {
      if (image.classList.contains('fade-in') === false && !ProductIdListAlgolia.includes(modelId)) {
        ProductIdList2.push(modelId);
        ProductIdListAlgolia.push(modelId);
        image.classList.add('fade-in');
        count += 1;
      }
      // multiColor DSM click
      else if (!ProductIdListAlgolia.includes(modelId)) {
        ProductIdListAlgolia.push(modelId);
        dsmClick(pImp, modelId);
      }
    } else if (image.classList.contains('fade-in') === false && !ProductIdListCategory.includes(modelId)) {
      ProductIdList2.push(modelId);
      ProductIdListCategory.push(modelId);
      image.classList.add('fade-in');
      count += 1;
    }
    // multiColor DSM click
    else if (!ProductIdListCategory.includes(modelId)) {
      ProductIdListCategory.push(modelId);
      dsmClick(pImp, modelId);
    }
  }
};
export default DetectVisible;

import React, { useState, useEffect } from 'react';
import { useSelector, connect } from 'react-redux';
import actions from '../../../../redux/actions/actions';
import Script from 'next/script';
import { getCookie, setCookie } from '@/src/Utils/Cookies';
import {
  GAEvent_voiceSearchIntitate,
  GAEvent_voiceSearchSubmit,
  GAEvent_voiceSearchError,
} from '@/redux/actions/GAEventsActions/globalEvents/GASearchEvents';
import dynamic from 'next/dynamic';

const VoiceSearchUI = dynamic(() => import('./VoiceSearchUI'), {
  ssr: false,
});
const VoiceSearchToolTip = dynamic(() => import('./VoiceSearchToolTip'), {
  ssr: false,
});
let finalTranscript = '';
let intervalId;

const micIcon = require('../../../assets/img/search.svg');

const VoiceSearch = ({ main = false, virtual = false, setVoiceError, toogleMicTooltipValue }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [searchPopup, setSearchPopup] = useState(false);

  const error = useSelector((state) => state.reducer.voiceSearchError);
  const texts = ["Men's Shoes", 'फिटनेस शॉर्ट्से', 'ஓடும் காலணிகள்'];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (toogleMicTooltipValue && getCookie('dkt_voiceSearchInfoTooltip') == null) {
      setSearchPopup(true);
      setCookie('dkt_voiceSearchInfoTooltip', true);
      setTimeout(() => {
        setSearchPopup(false);
      }, process.env.NEXT_PUBLIC_REVERIE_VOICE_SEARCH_INFO_TOOLTIP);
    } else {
      setSearchPopup(false);
    }
  }, [toogleMicTooltipValue]);

  const setError = (payload) => {
    setVoiceError(payload);
  };

  const startListening = () => {
    if (window.stt_stream) {
      clearInterval(intervalId);
      stt_stream.startSTT();
      setError(false);
    }
  };

  const closePopup = () => {
    stt_stream.stopSTT();
    setShowPopup(false);
    clearInterval(intervalId);
  };

  const onEnd = () => {
    if (finalTranscript.length === 0) {
      setError(true);
    } else {
      const textNode = document.querySelector('#interm_speech');
      if (textNode) {
        textNode.innerHTML = `"${finalTranscript}"`;
        textNode.classList.remove('font-medium');
        textNode.classList.add('font-bold');
        window.sessionStorage.setItem('fromVoiceSearch', 1);
        GAEvent_voiceSearchSubmit(finalTranscript);
        window.location.href = `/search/?query=${finalTranscript}`;
        closePopup();
      }
    }
  };

  const onError = () => {
    stt_stream.stopSTT();
    setError(true);
  };

  const onResult = (res) => {
    if (res.event == 'PARTIAL_RESULTS') {
      finalTranscript = res.data;
      const textNode = document.querySelector('#interm_speech');
      if (textNode) textNode.innerHTML = `"${finalTranscript}"`;
    } else if (res.event == 'FINAL_RESULT') {
      finalTranscript = res.data;
      onEnd();
    } else if (res.event == 'MSG_WEB_SOCKET') {
      let response = JSON.parse(res.data);
      if (response.final && response.display_text == '') {
        GAEvent_voiceSearchError(response.text, response.cause);
        onError();
      }
    }
  };

  const micClick = () => {
    if (virtual) {
      document.querySelector('#main').click();
    } else {
      GAEvent_voiceSearchIntitate();
      setShowPopup(true);
      startListening();
    }
  };

  useEffect(() => {
    if (error) {
      intervalId = setInterval(() => {
        setCurrentIndex((index) => (index + 1) % texts.length);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [setCurrentIndex, error]);

  return (
    <>
      <Script
        src="https://stt-sdk.reverieinc.com/bundle.js"
        strategy="afterInteractive"
        onLoad={() => {
          const initSTT = async () => {
            await stt_stream?.initSTT({
              appId: process.env.NEXT_PUBLIC_REVERIE_VOICE_SEARCH_APP_ID,
              apikey: process.env.NEXT_PUBLIC_REVERIE_VOICE_SEARCH_ID,
              language: 'en',
              domain: process.env.NEXT_PUBLIC_REVERIE_VOICE_SEARCH_DOMAIN,
              no_input_timeout: process.env.NEXT_PUBLIC_REVERIE_VOICE_SEARCH_NO_INPUT_TIMEOUT,
              silence: process.env.NEXT_PUBLIC_REVERIE_VOICE_SEARCH_SILENCE_TIMEOUT,
              logging: true,
              eventHandler: (res) => {
                onResult(res);
              },
              errorHandler: (error) => {
                GAEvent_voiceSearchError('', error.data);
              },
            });
          };

          initSTT();
        }}
      />
      <button type="button" onClick={micClick} className="w-7 h-7 px-2 py-2" id={main ? 'main' : ''}>
        <img src={micIcon} alt="mic" className="w-4 h-4" />
      </button>
      {searchPopup && (
        <VoiceSearchToolTip searchPopup={searchPopup} setSearchPopup={setSearchPopup} micClick={micClick} />
      )}
      {showPopup && (
        <VoiceSearchUI
          error={error}
          micClick={micClick}
          closePopup={closePopup}
          startListening={startListening}
          searchPopup={searchPopup}
          texts={texts}
          currentIndex={currentIndex}
          setSearchPopup={setSearchPopup}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  error: state.reducer.voiceSearchError,
});

const mapDispatchToProps = {
  setVoiceError: actions.voiceSearchErrorToggle,
};

export default connect(mapStateToProps, mapDispatchToProps)(VoiceSearch);

import React, { useEffect, useState } from 'react';
import { Col, Button } from 'reactstrap';
import { connect, useSelector } from 'react-redux';
import locationPromptActions from '@/redux/actions/LocationPrompt';
import { TabletMobile, Desktop } from '../CustomMediaQuery';
import { getCookie, setCookie, removeCookie } from '../../../Utils/Cookies';
import useToastHook from '@/src/hooks/useToastHook';

const TOOLTIP_SHOW_TIME = 5;

const PincodeHeader = ({ toggleLocationPrompt, getPincodeDetails, getUserLocation }) => {
  const pincode = useSelector((state) => state.reducer.pincode);
  const zipcodedetails = useSelector((state) => state.reducer.zipcodedetails);

  const { addToast } = useToastHook();
  const [pincodeTool, setPincodeTool] = useState(false);
  const getCatchmentDetails = getCookie('dkt_catchment') && JSON.parse(getCookie('dkt_catchment'));

  if (!pincode) {
    getUserLocation();
  }

  // condition to only send the pincode request once
  if (zipcodedetails === 'no data' && pincode) {
    getPincodeDetails(pincode);
  }
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  useEffect(() => {
    const pincodeTooltip = getCookie('dkt_pincodeTooltip');
    if (getCatchmentDetails?.storeName && getCatchmentDetails?.catchmentId == getCookie('dkt_storeId')) {
      addToast(`Welcome to ${getCatchmentDetails?.storeName}`, {
        appearance: 'success',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
      removeCookie('dkt_storeId');
    }
    if (!pincodeTooltip) {
      setCookie('dkt_pincodeTooltip', true);

      setPincodeTool(true);
      setTimeout(() => {
        setPincodeTool(false);
      }, 1000 * TOOLTIP_SHOW_TIME);
    }
  }, []);

  const displayHeaderWithZipcodeDetails = () => {
    return (
      <div
        className="p-4"
        style={{
          backgroundColor: zipcodedetails.colorCode,
        }}
      >
        <Col className="p-0">
          <div className="text-center text-blue-400 d-flex flex-col">
            {zipcodedetails.active ? (
              <p className="m-0 text-16">
                Delivery Location
                <b>{pincode}</b> ({capitalizeFirstLetter(zipcodedetails.zoneType.toLowerCase())} Zone)
                <a href="/shop/delivery-covid19-2020" target="_blank">
                  <span
                    className="info-icon ml-2"
                    style={{
                      color: zipcodedetails.colorCode,
                    }}
                  >
                    i
                  </span>
                </a>
              </p>
            ) : (
              <p className="m-0">
                Delivery Location
                <b>{pincode}</b>
                (Non Serviceable)
                <a href="/shop/delivery-covid19-2020" target="_blank">
                  <span
                    className="info-icon ml-2"
                    style={{
                      color: zipcodedetails.colorCode,
                    }}
                  >
                    i
                  </span>
                </a>
              </p>
            )}
            <Button
              className="text-white bg-transparent border-none pt-0 pr-0 pb-0.5 pl-0.5 text-14 my-0 mx-1"
              type="button"
              onClick={() => toggleLocationPrompt()}
              data-test-id="header:delivery-location-with-zip"
            >
              CHANGE
            </Button>
          </div>
        </Col>
      </div>
    );
  };

  const displayHeaderWithoutZipcodeDetails = () => {
    return (
      <div className="relative">
        <p className="text-center text-12 lg:text-14 whitespace-nowrap text-grey-900">
          {' '}
          {getCatchmentDetails?.storeName ? 'Current Location' : 'Delivery Location'}
        </p>
        {getCatchmentDetails?.storeName ? (
          <div className="flex items-center float-right">
            <span className="text-12 font-bold text-blue-400">{getCatchmentDetails?.storeName}</span>
          </div>
        ) : (
          <div className="flex justify-center">
            <span className="text-14 lg:text-16 font-bold text-blue-400">{pincode}</span>
            <button
              type="button"
              className="ml-1 text-12 lg:text-14 text-blue-400 uppercase underline border-none  outline-none"
              onClick={() => toggleLocationPrompt()}
              data-test-id="header:delivery-location-without-zip"
            >
              Change
            </button>
          </div>
        )}
        {pincodeTool && (
          <div className="absolute top-full right-0 min-w-[180px] max-w-sm z-10">
            <div className="w-0 h-0 ml-auto -mb-px mr-3 border-t-0 border-l-8 border-r-8 border-b-8 border-l-transparent border-r-transparent border-grey-900"></div>
            <div className="p-3 bg-grey-900 text-12 text-white rounded">
              <div>Pincode for Personalised Shopping & to Check Availability</div>
            </div>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    const pincodeTooltip = getCookie('dkt_pincodeTooltip');
    if (!pincodeTooltip && window.innerWidth <= 768) toggleLocationPrompt();
  }, []);

  return (
    <>
      {zipcodedetails !== 'no data' && zipcodedetails !== 'processing' ? (
        zipcodedetails ? (
          displayHeaderWithZipcodeDetails()
        ) : (
          displayHeaderWithoutZipcodeDetails()
        )
      ) : (
        <>
          <Desktop>
            <div
              className="skeleton mt-0"
              style={{
                padding: '0.1rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <PincodeSkeleton />
            </div>
          </Desktop>
          <TabletMobile>
            <div
              className="skeleton"
              style={{
                padding: '0.1rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <PincodeSkeleton />
            </div>
          </TabletMobile>
        </>
      )}
    </>
  );
};

const PincodeSkeleton = () => {
  return (
    <>
      <div
        className="skeleton dark m-1"
        style={{
          height: 15,
          width: 80,
        }}
      />

      <div
        style={{
          display: 'flex',

          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          className="skeleton dark m-1"
          style={{
            height: 7,
            width: 40,
          }}
        />
        <div
          className="skeleton dark m-1"
          style={{
            height: 7,
            width: 40,
          }}
        />
      </div>
    </>
  );
};

const mapDispatchToProps = {
  toggleLocationPrompt: locationPromptActions.toggleLocationPrompt,
  getPincodeDetails: locationPromptActions.getPincodeDetails,
  getUserLocation: locationPromptActions.getUserLocation,
};

export default connect(null, mapDispatchToProps)(PincodeHeader);

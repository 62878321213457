let arrKeyValue = [];
let arrkeylabel = [];
let mostRelevant;

const filterDimensions = (searchState) => {
  // Code added by NAMAN start here for Algolia mobile filters and hiding best sellers floor on category page
  let filterApplied = 0;
  if (searchState.refinementList && Object.keys(searchState.refinementList).length) {
    filterApplied = 1;
    if (typeof window !== 'undefined' && window.innerWidth < 768) {
      document.querySelectorAll('.mob_filter_heading').forEach((item) => {
        item.className = 'mob_filter_heading active_filters';
      });
    }
  }

  if (searchState.range?.price) {
    if (typeof window !== 'undefined' && window.innerWidth < 768) {
      if (typeof searchState.range?.price.min === 'undefined' && typeof searchState.range?.price.max === 'undefined') {
        document.querySelectorAll('.ais-price').forEach((item) => {
          item.className = 'ais-price nav-item';
        });
      } else {
        filterApplied = 1;
        document.querySelectorAll('.ais-price').forEach((item) => {
          item.className = 'ais-price nav-item active_filters';
        });
        document.querySelectorAll('.mob_filter_heading').forEach((item) => {
          item.className = 'mob_filter_heading active_filters';
        });
      }
    } else if (
      typeof searchState.range?.price.min === 'undefined' &&
      typeof searchState.range?.price.max === 'undefined'
    ) {
      // do nothing
    } else {
      filterApplied = 1;
    }
  } else {
    document.querySelectorAll('.ais-price').forEach((item) => {
      item.className = 'ais-price nav-item';
    });
  }

  if (!filterApplied) {
    if (typeof window !== 'undefined' && window.innerWidth < 768) {
      document.querySelectorAll('.mob_filter_heading').forEach((item) => {
        item.className = 'mob_filter_heading';
      });
    }
  } else if (document.querySelector('.best_sellers')) {
    document.querySelector('.best_sellers').style.display = 'none';
  }

  if (typeof searchState.sortBy !== 'undefined' && searchState.sortBy && document.querySelector('.best_sellers')) {
    document.querySelector('.best_sellers').style.display = 'none';
  }

  if (typeof searchState.page !== 'undefined' && searchState.page && document.querySelector('.best_sellers')) {
    document.querySelector('.best_sellers').style.display = 'none';
  }

  if (
    typeof document !== 'undefined' &&
    document.querySelector('.best_sellers') &&
    !searchState?.page &&
    (!searchState?.sortBy || searchState?.sortBy === process.env.NEXT_PUBLIC_ALGOLIA_CATEGORY_INDEX) &&
    filterApplied === 0 &&
    Object.keys(searchState?.refinementList || {})?.length === 0
  ) {
    document.querySelector('.best_sellers').style.display = 'block';
  }

  if (searchState.refinementList || searchState.sortBy || searchState.range) {
    if (
      (searchState.refinementList && Object.keys(searchState.refinementList)) ||
      (searchState.range && Object.keys(searchState.range))
    ) {
      arrKeyValue = [];
      arrkeylabel = [];

      if (searchState.range?.price && Object.keys(searchState.range?.price)) {
        if (searchState.range?.price.min !== undefined || searchState.range?.price.max !== undefined) {
          const price = Object.keys(searchState.range);
          if (arrKeyValue.includes(price) === false) {
            arrkeylabel.push(price);
          }

          let priceValue = searchState.range?.price;
          if (priceValue.min === undefined) {
            priceValue = priceValue.max;
          } else if (priceValue.max === undefined) {
            priceValue = priceValue.min;
          } else {
            priceValue = `${priceValue.min} + to + ${priceValue.max}`;
          }
          arrKeyValue.push(priceValue);
        }
      }

      if (searchState.refinementList && Object.keys(searchState.refinementList)) {
        const arrkeylabel2 = Object.keys(searchState.refinementList);
        const arr = [];
        let label;
        arrkeylabel2.forEach((arrdata) => {
          if (arrdata.includes('tes_')) {
            const data2 = arrdata.split('tes_').pop();
            if (data2.includes('_en')) {
              label = data2.split('_en');
              arr.push(label[0]);
            } else {
              arr.push(data2);
            }
          } else if (arrdata.includes('_en')) {
            label = arrdata.split('_en');
            arr.push(label[0]);
          } else {
            arr.push(arrdata);
          }
        });
        arrkeylabel = [...arrkeylabel, ...arr];
        const data = Object.values(searchState.refinementList);

        data.map((val) => {
          val.map((value) => {
            arrKeyValue.push(value);
            return null;
          });
          return null;
        });
      }
    }
    if (searchState.sortBy && Object.keys(searchState.sortBy)) {
      const sort = searchState.sortBy.split('dosav2_').pop();
      if (
        (window.location.href.indexOf('query') > 1 && sort === 'India_preprod_dosav2') ||
        sort === 'India_prod_dosav2'
      ) {
        mostRelevant = 'Most Relevant';
      } else if (sort === 'category') {
        mostRelevant = 'Most Relevant';
      } else if (sort.includes('category_')) {
        mostRelevant = sort.split('category_')[1];
      } else if (window.location.href.indexOf('query') > 1) {
        mostRelevant = sort;
      }
    } else {
      mostRelevant = 'Most Relevant';
    }
    let filterDimension;
    if (window.location.href.indexOf('query') > 1) {
      filterDimension = {
        dimension34: mostRelevant,
        dimension35: arrkeylabel.toString(),
        dimension36: arrKeyValue.toString(),
        dimension37: 'Algolia_Filter',
      };
    } else {
      filterDimension = {
        dimension34: mostRelevant,
        dimension35: arrkeylabel.toString(),
        dimension36: arrKeyValue.toString(),
        dimension37: 'Category_Filter',
      };
    }
    localStorage.setItem('filterDimensions', JSON.stringify(filterDimension));
  }
};
export default filterDimensions;

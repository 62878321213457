import actions from '@/redux/actions/actions';
import cartPageActions from '@/redux/actions/CartPage';
import loginActions from '@/redux/actions/login';
import { DY_KeywordSearch } from '@/src/lib/dynamicYield/events';
import { amplitude_preSearchLinkSelected } from '@/redux/actions/GAEventsActions/globalEvents/GASearchEvents';
import { Transition } from '@headlessui/react';
import { VpLogoDecathlonLockupPrimaryBrandAsset } from '@vtmn-play/assets/react';
import {
  VpAwardIcon,
  VpCloseIcon,
  VpHeartIcon,
  VpMailIcon,
  VpMenuIcon,
  VpMessageIcon,
  VpSearchIcon,
  VpShoppingCartIcon,
  VpStoreIcon,
  VpUserIcon,
  VpWalletIcon,
} from '@vtmn-play/icons/react';
import classnames from 'classnames';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Configure, Index, InstantSearch, QueryRuleCustomData, connectSearchBox } from 'react-instantsearch-dom';
import { connect, useDispatch } from 'react-redux';
import FilterDimensions from '../../../src/components/Tagmanager/filterDimensions';
import { formatedSearchBarKeywords, searchClient, supportLink } from '../../../src/Utils';
import { getCookies } from '../../Utils/Cookies';
import { getSignInPageUrl } from '../../Utils/DKTLogin';
import { Desktop, Mobile, OnlyMobile } from '../common/CustomMediaQuery';
import PincodeHeader from '../common/GeoLocation/PincodeHeader';
import detectVisible from '../Tagmanager/DetectVisible';
import HeaderStyles from './Header.module.scss';
import MegaMenuMobileNew from './MegaMenu/MegaMenuMobileNew';
import Ribbons from './Ribbons/Ribbons';
import SearchBarAnimation from './SearchBarAnimation';
import VoiceSearch from './VoiceSearch/VoiceSearch';

const RecentViewsFloor = dynamic(() => import('../Algolia/SearchSuggestions/RecentViewsFloor'));

const SearchBox = dynamic(() => import('../Algolia/SearchBox'));
const RecentSearchSuggestions = dynamic(() => import('../Algolia/SearchSuggestions/RecentSearchSuggestions'));
const MegaMenuDesktop = dynamic(() => import('./MegaMenu/MegaMenuDesktop'), {
  ssr: false,
});
const VirtualSB = connectSearchBox(() => <span />);
const SuggestionContent = dynamic(() => import('../Algolia/SearchSuggestions/SuggestionContent'));
const VideoPopup = dynamic(() => import('../../../src/components/Floors/VideoPopup/VideoPopup'), {
  ssr: false,
});
const LocationPrompt = dynamic(() => import('../../../src/components/Header/LocationPrompt/LocationPrompt'), {
  ssr: false,
});
const TopProducts = dynamic(() => import('../Algolia/SearchSuggestions/TopProducts'));

const NoSearchResult = dynamic(() => import('../Algolia/NoSearchResult'), {
  ssr: false,
});

const TrendingSearch = dynamic(() => import('../Algolia/SearchSuggestions/TrendingSearch'), {
  ssr: false,
});

const Header = (props) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const toggleVerticalNavigation = () => dispatch(actions.toggleVerticalNavigation());

  const [searchState, setSearchState] = useState({});
  const [windowWidth, setWindowWidth] = useState(0);
  const [searchBarMobile, setSearchBarMobile] = useState(false);
  const [showMobileSearch, setShowMobileSearch] = useState(false);
  const [onScrollDown, setOnScrollDown] = useState(true);
  const [searchOverlay, setSearchOverlay] = useState(false);
  const [showMegaMenu, setShowMegaMenu] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [searchKeywords, setSearchKeywords] = useState({ ...props.searchBarKeywords });
  const [playanimation, setPlayAnimation] = useState(false);
  const [ribbonDisplayMessage, setRibbonDisplayMessage] = useState([]);
  const [formattedKeywordsSequenceList, setFormattedKeywordsSequenceList] = useState([]);
  const [ribbonshow, setRibbonShow] = useState(true);
  const [searchPlaceholderText, setSearchPlaceholderText] = useState('Search For 60+ Sports & 6000+ Products');
  const [toogleMicTooltipValue, toogleMicTooltip] = useState(false);

  const mwebFixedHeader = () => {
    if (window.innerWidth < 768) {
      let headerClasses = document.querySelector(`.${HeaderStyles.onScrollHeader}`)?.classList;
      if (headerClasses && window.scrollY >= 114 && !headerClasses.contains(`${HeaderStyles.fixed}`))
        headerClasses.add(HeaderStyles.fixed);
      if (headerClasses && window.scrollY < 114 && headerClasses.contains(`${HeaderStyles.fixed}`))
        headerClasses.remove(HeaderStyles.fixed);
    }
  };
  const handleScroll = () => {
    detectVisible();
    mwebFixedHeader();
  };
  const fetchSearchBarKeywords = () => {
    const { keywords = [], placeholder } = props.searchBarKeywords;

    if (keywords?.length) {
      setSearchKeywords({ keywords, placeholder });
      return;
    }

    props.storeSearchBarKeywords();
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    setSearchPlaceholderText(
      JSON.parse(localStorage.getItem('searchBarKeywords_data'))?.placeholder ||
        props.searchBarKeywords?.placeholder ||
        'Search For 60+ Sports & 6000+ Products',
    );

    window.addEventListener('scroll', handleScroll);
    props.checkLoginStatus();
    const { dkt_isLoggedIn } = getCookies();
    setIsUserLoggedIn(dkt_isLoggedIn);

    const onPageLoad = () => {
      setPlayAnimation(true);
      fetchSearchBarKeywords();
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
    }

    props.getCartCount();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('load', onPageLoad);
      document.querySelector('body').classList.remove('overflow-hidden');
      setWindowWidth(0);
    };
  }, []);

  useEffect(() => {
    if (playanimation && !searchKeywords?.keywords?.length) fetchSearchBarKeywords();
  }, [playanimation, props.searchBarKeywords]);

  useEffect(() => {
    if (searchKeywords.keywords?.length > 0)
      setFormattedKeywordsSequenceList(formatedSearchBarKeywords(searchKeywords.keywords));
  }, [searchKeywords?.keywords]);

  if (props.verticalNavigation) {
    typeof document !== 'undefined' && document.querySelector('body').classList.add('overflow-hidden');
  } else {
    typeof document !== 'undefined' && document.querySelector('body').classList.remove('overflow-hidden');
  }

  // Function to hide and show the overlay for search suggestions
  const setOverlayToggle = (param) => {
    setSearchOverlay(param);
  };

  // Function for different headers on mobile
  const searchToggle = () => {
    setShowMobileSearch(!showMobileSearch);
    toogleMicTooltip(!toggleVerticalNavigation);
    if (showMobileSearch) document.getElementById('search').focus();
  };

  // Function for search sortBy handler

  // Function to show vertical menu
  const showMenu = async () => {
    const { storeMegaMenuData, storeNewMegaMenuData, device } = props;
    setShowMegaMenu(true);
    storeMegaMenuData(device);
    storeNewMegaMenuData(device);
    document.querySelector('body').classList.add('overflow-hidden');
  };

  // explore click for GA data
  const exploreClick = (searchterm) => {
    DY_KeywordSearch({ type: 'SearchQuery', value: searchterm });
    amplitude_preSearchLinkSelected(searchterm, 'search term');
    window.dataLayer.push({
      event: 'InternalSearchExplore',
      InternalSearchExplore: searchterm,
    });
  };

  // Algolia search function, which changes the state of search
  const onSearchStateChange = (searchState) => {
    const filterDimensions = localStorage.getItem('filterDimensions');
    if (filterDimensions) {
      localStorage.removeItem('filterDimensions');
    }
    FilterDimensions(searchState);

    setSearchState({ ...searchState });
  };

  const {
    cartPage,
    suggestionSearchtxt,
    suggestionSearch,
    showSearchSuggestions,
    showTopProducts,
    videoId,
    locationPrompt,
    numberOfTopProducts,
    showRecentSearches,
    ribbonBanners,
    getRibbonBannersData,
  } = props;

  if (suggestionSearchtxt != searchState.query && suggestionSearchtxt) {
    onSearchStateChange({
      query: suggestionSearchtxt,
    });
  }

  // ribbon banner data
  const fetchRibbonData = async (route) => {
    const data = await getRibbonBannersData(route);
    setRibbonDisplayMessage(data || []);
  };

  const RibbonDisplayPrevent = router?.asPath === '/wishlist' || router?.asPath.includes('account');

  useEffect(() => {
    if (ribbonBanners?.length) {
      setRibbonDisplayMessage(ribbonBanners);
    } else {
      const route = window.location.href;
      fetchRibbonData(route);
    }
  }, []);

  // Added seperate redirection function to avoid code duplication
  const searchRedirection = () => {
    return (
      <QueryRuleCustomData
        transformItems={(items) => {
          const match = items.find((data) => Boolean(data.redirect));
          if (match && match.redirect) {
            window.location.href = match.redirect;
          }
          return [];
        }}
      >
        {() => null}
      </QueryRuleCustomData>
    );
  };

  const handleScrollClick = () => {
    setTimeout(() => window.scrollTo(0, 0), 2000);
    router.push('/');
  };

  const logoutHandler = (e) => {
    e.preventDefault();
    if (typeof window === 'object') {
      window.location = `${process.env.NEXT_PUBLIC_SITE_URL}/api/logout`;
    }
  };

  const redirectToSignInPage = (event) => {
    event.preventDefault();
    window.location = getSignInPageUrl();
  };

  return (
    <>
      <InstantSearch
        indexName={process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_INDEX}
        searchClient={searchClient}
        onSearchStateChange={onSearchStateChange}
        searchState={searchState}
      >
        {searchRedirection()}
        <Index indexName={process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_INDEX}>
          <Configure hitsPerPage={4} />
        </Index>
        <Index indexName={process.env.NEXT_PUBLIC_ALGOLIA_SUGGESTION_INDEX} />
        <VirtualSB defaultRefinement={searchState.query} />
        {!showMobileSearch && (
          <header className="top-0 z-50 md:z-30 lg:sticky">
            <div className="relative z-40 md:z-auto">
              <div className="bg-white">
                <div className="px-4 py-3 2xl:px-16 xl:py-4">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center w-1/2 md:w-auto">
                      <button
                        type="button"
                        aria-label="mega-menu"
                        className="flex items-center"
                        onClick={() => {
                          showMenu();
                          toggleVerticalNavigation();
                        }}
                      >
                        <div data-test-id="header:mega-menu-button">
                          {props.verticalNavigation ? <VpCloseIcon scale={20} size={32} /> : <VpMenuIcon size={32} />}
                        </div>
                        <p className="hidden ml-4 leading-5 text-left  uppercase lg:block text-16">
                          All <br /> Sports
                        </p>
                      </button>
                      <a
                        href="/"
                        aria-label="decathlon logo"
                        className="ml-3 cursor-default md:ml-6 lg:ml-12"
                        data-test-id="header:decathlon-logo"
                        onClick={handleScrollClick}
                      >
                        <VpLogoDecathlonLockupPrimaryBrandAsset className="w-full lg:w-36 cursor-pointer" />
                      </a>
                    </div>
                    <div className="flex items-center flex-grow lg:pr-0 md:ml-2 xl:pr-6 xl:pl-14 2xl:pr-4 ">
                      <div className="static flex w-full rounded-full lg:relative lg:h-10">
                        <div className="hidden w-full overflow-hidden rounded-full md:block md:relative">
                          {searchOverlay && (
                            <div
                              className="inset-0 z-40 hidden overlay md:block"
                              onClick={() => setSearchOverlay(false)}
                            />
                          )}
                          <div
                            className={classnames('md:relative h-full  bg-white', {
                              'z-50': searchState.query || searchOverlay,
                            })}
                          >
                            {/* <VpSearchIcon /> */}
                            <SearchBox
                              suggestionSearch={suggestionSearch}
                              setSearchOverlay={setSearchOverlay}
                              className="w-full h-full px-3 outline-none md:py-4 lg:py-2 text-12 lg:text-[1rem] tracking-[1px]"
                              autoFocus={false}
                              showRecentSearches={showRecentSearches}
                              searchKeywords={searchKeywords}
                              closeNavigation={() => {
                                props.verticalNavigation && toggleVerticalNavigation();
                              }}
                            />
                          </div>
                        </div>
                        <div className="hidden md:block">
                          {searchOverlay && !searchState.query && (
                            <div className="fixed top-0 left-0 z-50 w-full h-full lg:h-auto lg:absolute lg:top-full md:mt-16 lg:mt-0 md:pb-16 lg:pb-0 lg:pt-3">
                              <div
                                className={`rounded md:h-full lg:max-h-[32rem] bg-grey-100 lg:bg-white md:flex md:flex-col md:overflow-y-auto`}
                              >
                                <div className={`${HeaderStyles.searchSugestionsBlock}`}>
                                  <RecentSearchSuggestions items={[]} />
                                </div>
                                <div>
                                  <TrendingSearch />
                                </div>
                                <div>
                                  <RecentViewsFloor />
                                </div>
                              </div>
                            </div>
                          )}
                          {searchOverlay && searchState.query && (
                            <div className="fixed top-0 left-0 z-50 w-full h-full lg:h-auto lg:absolute lg:top-full md:mt-16 lg:mt-0 md:pb-16 lg:pb-0 lg:pt-3">
                              <div
                                className={`rounded md:h-full lg:max-h-[32rem] bg-grey-100 lg:bg-white md:flex md:flex-col md:overflow-y-auto`}
                              >
                                <div
                                  className={classnames(
                                    `flex  p-4 md:flex-1 md:overflow-y-auto ${HeaderStyles.searchSuggestionsRow}`,
                                    {
                                      hidden: !showSearchSuggestions && !showTopProducts,
                                    },
                                  )}
                                >
                                  <div
                                  // className={`w-full md:p-3 md:w-1/4 md:bg-white lg:p-0 ${HeaderStyles.searchSuggestionsList}`}
                                  >
                                    <SuggestionContent />
                                  </div>
                                  <div className={`w-full bg-white md:p-3 lg:p-0 ${HeaderStyles.topProductsList}`}>
                                    <TopProducts closeSearchSuggestions={() => setOverlayToggle(false)} />
                                  </div>
                                </div>
                                {numberOfTopProducts > 0 && (
                                  <div className="p-4 mt-2 bg-grey-100">
                                    <a
                                      href={`/search?query=${searchState.query}`}
                                      onClick={() => exploreClick(searchState.query)}
                                    >
                                      <p className="flex">
                                        <span>Explore all products matching</span>
                                        <span className="mx-1 font-semibold">"{searchState.query}"</span>
                                        <span>({numberOfTopProducts})</span>
                                        <img src={require('../../assets/img/next-arrow.svg')} className="ml-6" />
                                      </p>
                                    </a>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {windowWidth >= 800 && (
                        <div className="ml-3 md:block lg:ml-4 xl:ml-14">
                          <PincodeHeader />
                        </div>
                      )}
                    </div>
                    <div className="flex items-center lg:hidden">
                      {isUserLoggedIn ? (
                        <a
                          href="/account"
                          prefetch={false}
                          className="px-2 md:px-3 py-1 border-1 uppercase border border-[#000] rounded-full text-10"
                        >
                          Account
                        </a>
                      ) : (
                        <>
                          <a
                            type="button"
                            className="px-2 md:px-3 py-1 border-1 uppercase border border-[#000] rounded-full text-10"
                            data-test-id="header:signin-button"
                            onClick={redirectToSignInPage}
                            href={getSignInPageUrl()}
                          >
                            Sign In
                          </a>
                        </>
                      )}
                      {windowWidth <= 800 && (
                        <div className="ml-3">
                          <PincodeHeader />
                        </div>
                      )}
                    </div>
                    <div className="hidden -mx-2 lg:flex 2xl:ml-6">
                      {isUserLoggedIn ? (
                        <a href={'/account'} className="mx-5" prefetch={false}>
                          <div className="relative flex flex-col group">
                            <VpUserIcon className="mx-auto" />
                            <p className="mt-1 text-center  text-[13px]" data-test-id="header:account-link">
                              Account
                            </p>
                            <Desktop>
                              <div className="absolute hidden -translate-x-1/2 group-hover:block left-1/2 top-full w-60">
                                <div className="absolute z-10 w-6 h-6 mt-1 rotate-45 -translate-x-1/2 bg-white left-1/2"></div>
                                <div className="relative z-20 w-full mt-4 overflow-hidden bg-white rounded-sm shadow">
                                  <Link href="/account/my-profile?tab=user-identity" prefetch={false}>
                                    <div className="flex items-center py-4 pl-14 hover:bg-grey-100">
                                      <VpUserIcon className="w-5 h-5" />
                                      <p className="ml-3">My Profile</p>
                                    </div>
                                  </Link>
                                  <Link href="/account/orders-returns?tab=order-returns" prefetch={false}>
                                    <div className="flex items-center py-4 pl-14 hover:bg-grey-100">
                                      <VpShoppingCartIcon className="w-5 h-5" />{' '}
                                      <p className="ml-3">Orders & Returns</p>
                                    </div>
                                  </Link>
                                  <Link href="/account/wallet?tab=wallet" prefetch={false}>
                                    <div className="flex items-center py-4 pl-14 hover:bg-grey-100">
                                      <VpWalletIcon className="w-5 h-5" /> <p className="ml-3">Wallet</p>
                                    </div>
                                  </Link>
                                  <Link href="/account/rewards?tab=rewards" prefetch={false}>
                                    <div className="flex items-center py-4 pl-14 hover:bg-grey-100">
                                      <VpAwardIcon className="w-5 h-5" /> <p className="ml-3">Sporty Rewards</p>
                                    </div>
                                  </Link>
                                  <Link href="/account/address?tab=address" prefetch={false}>
                                    <div className="flex items-center py-4 pl-14 hover:bg-grey-100">
                                      <VpMailIcon className="w-5 h-5" />
                                      <p className="ml-3">My Addresses</p>
                                    </div>
                                  </Link>
                                  <div
                                    type="button"
                                    className="flex items-center w-full py-4 pl-14 hover:bg-grey-100"
                                    aria-hidden="true"
                                    onClick={logoutHandler}
                                  >
                                    <div className="w-6 border border-orange-300"></div>
                                    <p className="ml-3 text-orange-400">Logout</p>
                                  </div>
                                </div>
                              </div>
                            </Desktop>
                          </div>
                        </a>
                      ) : (
                        <a className="mx-5" onClick={redirectToSignInPage} href={getSignInPageUrl()}>
                          <div className="relative flex flex-col group">
                            <VpUserIcon size={24} className="mx-auto" />
                            <p className="mt-1 text-center  text-[13px]" data-test-id="header:account-link">
                              Sign In
                            </p>
                          </div>
                        </a>
                      )}
                      <a href="/store" className="mx-5">
                        <div className="relative flex flex-col group">
                          <VpStoreIcon size={24} className="mx-auto" />
                          <p className="mt-1 text-center  text-[13px]" data-test-id="header:mystore-link">
                            My Store
                          </p>
                        </div>
                      </a>
                      <a href={supportLink} className="mx-5">
                        <div className="relative flex flex-col group">
                          <VpMessageIcon size={24} className="mx-auto" />
                          <p className="mt-1 text-center  text-[13px] " data-test-id="header:support-link">
                            Support
                          </p>
                        </div>
                      </a>
                      <Link href="/wishlist" className="mx-5" prefetch={false}>
                        <div className="">
                          <div className="relative">
                            <VpHeartIcon size={24} className="mx-auto" />
                          </div>
                          <p className="mt-1 text-center  text-[13px]" data-test-id="header:wishlist-link">
                            Wishlist
                          </p>
                        </div>
                      </Link>
                      <Link href="/cart" aria-label="cart" className="mx-5" prefetch={false}>
                        <div className="">
                          <div className="relative">
                            {cartPage.cartCount > 0 && (
                              <div className="absolute top-0 right-0 flex items-center justify-center w-4 h-4 pt-px -mt-2 -mr-3 font-bold bg-blue-400 text-white rounded-full xl:w-5 xl:h-5 text-10 xl:text-12">
                                {cartPage.cartCount}
                              </div>
                            )}
                            <VpShoppingCartIcon size={24} className="mx-autp" />
                          </div>
                          <p className="mt-1 text-center  text-[13px]" data-test-id="header:cart-link">
                            Cart
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="flex mt-2 md:hidden">
                    <div className="flex items-center flex-grow">
                      <div className="relative w-full">
                        <div
                          className="block w-full py-3 pl-3 pr-4 rounded-full outline-none text-12 bg-grey-50"
                          onClick={() => {
                            setShowMobileSearch(true);
                            toogleMicTooltip(true);
                          }}
                        >
                          {formattedKeywordsSequenceList?.length > 0 ? (
                            <SearchBarAnimation
                              speed={1}
                              formattedKeywordsSequenceList={formattedKeywordsSequenceList}
                              searchBarAnimation={true}
                            />
                          ) : (
                            <span className="flex items-center whitespace-nowrap  ">
                              <VpSearchIcon className="w-5 text-grey-500 mr-2" />
                              <span>{searchPlaceholderText}</span>
                            </span>
                          )}
                        </div>

                        <div className="absolute top-[8px] right-2">
                          <VoiceSearch main toogleMicTooltipValue={toogleMicTooltipValue} />
                        </div>
                      </div>
                      <span>
                        <Desktop>
                          <VpSearchIcon />
                          <input
                            type="text"
                            className="hidden w-full px-3 py-3 rounded-md outline-none"
                            defaultValue={searchPlaceholderText}
                          />
                        </Desktop>
                      </span>
                    </div>
                    <div className="flex justify-right items-center">
                      <Link href="/wishlist" prefetch={false} legacyBehavior>
                        <div className="relative">
                          <VpHeartIcon className="w-6 ml-3" />
                        </div>
                      </Link>
                    </div>
                    <div className="flex justify-right items-center">
                      <Link href="/cart" aria-label="cart" prefetch={false}>
                        <div className="relative">
                          {cartPage.cartCount > 0 && (
                            <div className="absolute top-0 right-0 flex items-center justify-center w-4 h-4 pt-px -mt-2 -mr-2 italic font-bold bg-blue-400 text-[#FFFFFF] rounded-full text-10">
                              {cartPage.cartCount}
                            </div>
                          )}

                          <VpShoppingCartIcon className="w-6 ml-3" />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <Desktop>
                <Transition
                  show={props.verticalNavigation}
                  enterFrom="translate-y-2 opacity-0"
                  enter="ease-out duration-500"
                  enterTo="translate-y-0 opacity-100"
                  leaveFrom="translate-y-0 opacity-100"
                  leave="ease-out duration-500"
                  leaveTo="translate-y-2 opacity-0"
                >
                  <div className="absolute w-full top-full">
                    <MegaMenuDesktop />
                  </div>
                </Transition>
              </Desktop>
            </div>
            {searchBarMobile && (
              <OnlyMobile>
                <div className={!showMobileSearch ? HeaderStyles.remove : HeaderStyles.add}>
                  <div className="flex items-center justify-between">
                    <div data-test-id="header:mega-menu-button">
                      {props.verticalNavigation ? <VpCloseIcon scale={20} size={32} /> : <VpMenuIcon size={32} />}
                    </div>
                    <div className={HeaderStyles.searchBar}>
                      <VpSearchIcon />
                      <div className={HeaderStyles.searchPlaceholder} onClick={searchToggle}>
                        {searchState.query ? (
                          searchState.query
                        ) : (
                          <>
                            {formattedKeywordsSequenceList?.length > 0 ? (
                              <SearchBarAnimation
                                speed={1}
                                formattedKeywordsSequenceList={formattedKeywordsSequenceList}
                                searchBarAnimation={true}
                              />
                            ) : (
                              <>{searchPlaceholderText}</>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="pincode">
                      <PincodeHeader />
                    </div>
                  </div>
                </div>
              </OnlyMobile>
            )}
            <OnlyMobile>
              <InstantSearch
                indexName={process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_INDEX}
                searchClient={searchClient}
                onSearchStateChange={onSearchStateChange}
                searchState={searchState}
              >
                <Index indexName={process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_INDEX}>
                  <Configure hitsPerPage={4} />
                </Index>
                <Index indexName={process.env.NEXT_PUBLIC_ALGOLIA_SUGGESTION_INDEX} />
                <VirtualSB defaultRefinement={searchState.query} />
                {searchRedirection()}
                {showSearchSuggestions && (
                  <>
                    <div className={`${HeaderStyles.recentSearchSugestionsBlock}`}>
                      <RecentSearchSuggestions items={[]} />
                    </div>
                    <div>
                      <TrendingSearch />
                    </div>
                  </>
                )}
              </InstantSearch>
              {onScrollDown && (
                <div className={`${HeaderStyles.onScrollHeader} px-4 py-3`}>
                  <button
                    type="button"
                    className="flex items-center"
                    onClick={() => {
                      showMenu();
                      toggleVerticalNavigation();
                    }}
                  >
                    <div data-test-id="header:mega-menu-button">
                      {props.verticalNavigation ? <VpCloseIcon scale={20} size={32} /> : <VpMenuIcon size={32} />}
                    </div>
                    <p className="hidden ml-4 leading-5 text-left  uppercase lg:block text-16">
                      All <br /> Sports
                    </p>
                  </button>
                  <div className={`${HeaderStyles.searchBar} ml-3 flex-grow`}>
                    {/* <img src={require('../../assets/img/search.svg')} alt="Search" /> */}
                    <div className="absolute top-[3px] right-1">
                      <VoiceSearch virtual />
                    </div>

                    <div className={HeaderStyles.searchPlaceholder} onClick={searchToggle} aria-hidden="true">
                      {searchState.query ? (
                        searchState.query
                      ) : (
                        <>
                          {formattedKeywordsSequenceList?.length > 0 ? (
                            <SearchBarAnimation
                              speed={1}
                              formattedKeywordsSequenceList={formattedKeywordsSequenceList}
                              searchBarAnimation={true}
                            />
                          ) : (
                            <>{searchPlaceholderText}</>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className={HeaderStyles.usefulLinks}>
                    <Link href="/wishlist" prefetch={false}>
                      <VpHeartIcon className="w-5 xs:w-6 ml-3" />
                    </Link>
                  </div>
                  <div className={HeaderStyles.usefulLinks}>
                    <Link href="/cart" aria-label="cart" prefetch={false} legacyBehavior>
                      <div className="relative">
                        {cartPage.cartCount > 0 && (
                          <div className="absolute top-0 right-0 flex items-center justify-center w-4 h-4 pt-px -mt-2 -mr-2 italic font-bold bg-blue-400 text-white rounded-full text-10">
                            {cartPage.cartCount}
                          </div>
                        )}

                        <VpShoppingCartIcon className="w-5 xs:w-6 ml-3" />
                      </div>
                    </Link>
                  </div>
                </div>
              )}
            </OnlyMobile>
          </header>
        )}
        <div>
          {ribbonDisplayMessage?.length > 0 && ribbonshow && !RibbonDisplayPrevent && (
            <Ribbons ribbonDisplayMessage={ribbonDisplayMessage} setRibbonShow={setRibbonShow} />
          )}
        </div>
        <Transition
          show={showMobileSearch}
          className="fixed top-0 z-50 w-full md:hidden"
          enterFrom="-translate-y-80"
          enter="ease-out duration-300"
          enterTo="-translate-y-0"
          leaveFrom="-translate-y-0"
          leave="ease-out duration-300"
          leaveTo="-translate-y-80"
        >
          <div className="flex w-full px-4 py-3 bg-white">
            <div className="relative w-full">
              <div className="pr-6 overflow-hidden bg-red rounded-md">
                {/* <img
                    src={require('../../assets/img/search.svg')}
                    alt="Search"
                    className="absolute right-0 w-4 mr-3 -translate-y-1/2 top-1/2"
                  /> */}
                <Desktop>
                  <VpSearchIcon />
                </Desktop>
                <SearchBox
                  suggestionSearch={suggestionSearch}
                  setSearchOverlay={setSearchOverlay}
                  className="w-full py-3 pl-1 pr-1 mr-1 outline-none text-12"
                  showRecentSearches={showRecentSearches}
                  searchKeywords={searchKeywords}
                  noSearchResults={numberOfTopProducts === 0}
                />
              </div>

              <div className="absolute top-[6px] right-6 ">
                <VoiceSearch toogleMicTooltipValue={toogleMicTooltipValue} />
              </div>
            </div>
            <button type="button" className="ml-4  text-14 xs:text-16" onClick={() => setShowMobileSearch(false)}>
              Cancel
            </button>
          </div>
          {!searchState.query && (
            <div className="flex flex-col h-[100vh] pb-10 overflow-y-scroll overflow-x-hidden bg-grey-100 gap-2">
              <div className="mt-2">
                <RecentSearchSuggestions items={[]} />
              </div>
              <div>
                <TrendingSearch />
              </div>
              <div>
                <RecentViewsFloor />
              </div>
            </div>
          )}
        </Transition>
        {showMobileSearch && searchState.query && (
          <div className="fixed inset-0 z-40 h-full lg:hidden">
            <div className={`flex flex-col h-full ${numberOfTopProducts ? 'pt-14' : ''} bg-grey-100`}>
              <div className="p-4 mt-2 overflow-y-auto ">
                <div
                  className={classnames('px-4 py-3 bg-white', {
                    hidden: !showSearchSuggestions,
                  })}
                >
                  <SuggestionContent />
                </div>
                <div
                  className={classnames('px-4 py-3 mt-4 bg-white', {
                    hidden: !showTopProducts,
                  })}
                >
                  <TopProducts
                    closeSearchSuggestions={() => {
                      searchToggle();
                      setOverlayToggle(false);
                    }}
                  />
                </div>
              </div>
              {numberOfTopProducts > 0 ? (
                <div className="p-4 mt-2 bg-white">
                  <a href={`/search?query=${searchState.query}`} onClick={() => exploreClick(searchState.query)}>
                    <p className="flex">
                      <span>Explore all products matching</span>
                      <span className="mx-1 font-semibold">"{searchState.query}"</span>
                      <span>({numberOfTopProducts})</span>
                      <img src={require('../../assets/img/next-arrow.svg')} className="ml-6" />
                    </p>
                  </a>
                </div>
              ) : (
                <div className="p-4 bg-white overflow-y-auto">
                  <NoSearchResult />
                </div>
              )}
            </div>
          </div>
        )}
      </InstantSearch>
      <Mobile>
        <Transition
          show={props.verticalNavigation}
          className="fixed top-0 z-50"
          enterFrom="-left-full"
          enter="transition-left ease-out duration-300"
          enterTo="left-0"
          leaveFrom="left-0"
          leave="transition-left ease-out duration-300"
          leaveTo="-left-full"
        >
          <MegaMenuMobileNew />
        </Transition>
      </Mobile>
      <Transition
        show={props.verticalNavigation}
        className="fixed z-40 lg:z-20"
        enterFrom="opacity-0"
        enter="transition-opacity ease-out duration-300"
        enterTo="opacity-100"
        leaveFrom="opacity-100"
        leave="transition-opacity ease-out duration-300"
        leaveTo="opacity-0"
      >
        <div className="z-40 opacity-25 overlay" onClick={() => toggleVerticalNavigation()}></div>
      </Transition>
      {locationPrompt && <LocationPrompt />}
      {videoId && <VideoPopup />}
    </>
  );
};

const mapStateToProps = (state) => ({
  searchBarKeywords: state.reducer.searchBarKeys,
  cartPage: state.reducer.cartPage,
  device: state.reducer.device,
  locationPrompt: state.reducer.locationPrompt,
  numberOfTopProducts: state.reducer.numberOfTopProducts,
  showSearchSuggestions: state.reducer.showSearchSuggestions,
  showTopProducts: state.reducer.showTopProducts,
  suggestionSearchtxt: state.reducer.suggestionSearchtxt,
  verticalNavigation: state.reducer.verticalNavigation,
  videoId: state.reducer.videoId,
  ribbonBanners: state.reducer.ribbonBanners,
});

const mapDispatchToProps = {
  checkLoginStatus: loginActions.checkLoginStatus,
  getCartCount: cartPageActions.getCartCount,
  storeMegaMenuData: actions.storeMegaMenuData,
  storeNewMegaMenuData: actions.storeNewMegaMenuData,
  suggestionSearch: actions.suggestionSearch,
  showRecentSearches: actions.showRecentSearches,
  storeSearchBarKeywords: actions.storeSearchBarKeywords,
  getRibbonBannersData: actions.getRibbonDisplayMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import actions from '@/redux/actions/actions';
import { useSelectorHook } from '@/src/Hooks/useSelectorHook';

const LeftPane = dynamic(() => import('./LeftPane'), { ssr: false });
const RightPane = dynamic(() => import('./RightPane'), { ssr: false });
const TopPane = dynamic(() => import('./TopPane'), { ssr: false });

const MegaMenuMobileNew = () => {
  return (
    <div className="bg-white h-full w-screen">
      <div className="flex w-full">
        <TopPane />
      </div>
      <div className="flex flex-row h-[95vh]">
        <LeftPane />
        <RightPane />
      </div>
    </div>
  );
};

export default MegaMenuMobileNew;

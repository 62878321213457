import DeviceContext, { deviceTypes, withDeviceContext } from '@/context/DeviceContext';
import { useContext } from 'react';

export const isDesktop = () => {
  const { contextDevice } = useContext(DeviceContext);
  return contextDevice === deviceTypes.desktop;
};

export const isTabletMobile = () => {
  const { contextDevice } = useContext(DeviceContext);
  return contextDevice === deviceTypes.tablet || contextDevice === deviceTypes.mobile;
};

export const Desktop = withDeviceContext(({ children, contextDevice }) => {
  return contextDevice === deviceTypes.desktop ? children : null;
});

export const Tablet = withDeviceContext(({ children, contextDevice }) => {
  return contextDevice === deviceTypes.tablet ? children : null;
});

export const Mobile = withDeviceContext(({ children, contextDevice }) => {
  return contextDevice === deviceTypes.mobile || contextDevice === deviceTypes.tablet ? children : null;
});

export const OnlyMobile = withDeviceContext(({ children, contextDevice }) => {
  return contextDevice === deviceTypes.mobile ? children : null;
});

export const TabletDesktop = withDeviceContext(({ children, contextDevice }) => {
  return contextDevice !== deviceTypes.mobile ? children : null;
});

export const Default = withDeviceContext(({ children, contextDevice }) => {
  return contextDevice === deviceTypes.desktop ? children : null;
});

export const TabletMobile = withDeviceContext(({ children, contextDevice }) => {
  return contextDevice !== deviceTypes.desktop ? children : null;
});

export const Allscreen = ({ children }) => {
  return children;
};

import { showToastMessage, hideToastMessage } from '@/redux/actions/toastMessageActions';
import { useDispatch } from 'react-redux';

type optionsT = {
  heading: string;
  appearance: string;
  autoDismiss: boolean;
  autoDismissTimeout: number;
};

export default function useToastHook() {
  const dispatch = useDispatch();

  const addToast = (message: string, options: optionsT) => {
    // console.log({ message, options }, options?.appearance);
    dispatch(
      showToastMessage({
        type: 'toast',
        appearance: options?.appearance || '',
        message: message,
        heading: options?.heading || '',
        timeout: options?.autoDismissTimeout || 3000,
      }),
    );
  };

  const removeAllToasts = () => {
    dispatch(hideToastMessage());
  };

  return { addToast, removeAllToasts };
}

export const withToastManager = (Component: any) => {
  return <Component />;
};

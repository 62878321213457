/* eslint-disable react/no-unescaped-entities */

import { VpSearchIcon } from '@vtmn-play/icons/react';
import React from 'react';
import { TypeAnimation } from 'react-type-animation';

const baseString = 'Search for  ';

const SearchBarAnimation = ({ speed = 1, formattedKeywordsSequenceList, searchBarAnimation }) => {
  return (
    <div
      className={`py-0 md:py-4 lg:py-2 w-full flex items-center  h-full px-0 xs:px-1 md:px-3 outline-none text-[#8996A2]  text-12 sm:text-14 lg:text-[1rem] sm:tracking-[1px] ${
        !searchBarAnimation ? ' hidden' : ''
      }`}
    >
      <VpSearchIcon className="mr-2 text-grey-500 w-5" />
      <span className="!mr-1">{baseString}</span>
      <TypeAnimation
        sequence={[...formattedKeywordsSequenceList]}
        speed={speed} // Custom Speed from 1-99
        wrapper="span"
        repeat={Infinity}
        style={{ fontWeight: '700' }}
      />
    </div>
  );
};

export default SearchBarAnimation;

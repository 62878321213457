import React, { useEffect, useState } from 'react';
import closeIcon from '../../../../src/assets/img/close-ribbon.svg';
import classNames from 'classnames';
import actions from '../../../../redux/actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Ribbons = ({ ribbonDisplayMessage, setRibbonShow, setRibbonBannerInCategoryPage }) => {
  const [displayMessage, setDisplayMessage] = useState(ribbonDisplayMessage[0]);
  let counter = 0;

  useEffect(() => {
    if (ribbonDisplayMessage.length > 1) {
      let cIcon = document.querySelector('#close-icon');
      if (cIcon) {
        cIcon.classList?.add('hidden');
      }
      document.querySelector('#text-message')?.classList?.add('slide-right');
      setTimeout(() => {
        document.querySelector('#text-message')?.classList?.remove('slide-right');
        if (cIcon) {
          cIcon.classList?.remove('hidden');
        }
      }, 600);
    }
  }, [displayMessage]);

  useEffect(() => {
    let timer;
    if (ribbonDisplayMessage.length > 1) {
      timer = setInterval(() => {
        if (counter === ribbonDisplayMessage.length - 1) {
          counter = 0;
          setDisplayMessage(ribbonDisplayMessage[counter]);
        } else {
          counter = counter + 1;
          setDisplayMessage(ribbonDisplayMessage[counter]);
        }
      }, 3000);
    }
    return () => {
      clearInterval(timer);
    };
  }, []);

  const boldTextInAsterisks = (sentence) => {
    return sentence.replace(/\*(.*?)\*/g, '<b>$1</b>');
  };

  const anchorTagInPlus = (sentence, link) => {
    const regex = /\+(.*?)\+/g;
    return sentence.replace(regex, `<a style="text-decoration: underline;" href="${link}">$1</a>`);
  };

  const RibbonDisplayMessage = (msg, link) => {
    if (msg === undefined || msg === '') {
      return '';
    }
    if (msg.length > 100) {
      msg = msg.substring(0, 100) + '...';
    }

    msg = boldTextInAsterisks(msg);
    const res = anchorTagInPlus(msg, link);
    return res;
  };

  return (
    <div
      data-test-id="headers:ribbon-banner"
      className={classNames('relative w-full py-2 flex items-center justify-center px-10 text-center')}
      style={{ backgroundColor: displayMessage.ribbonBackgroundColour }}
      id="ribbon-container"
    >
      <span
        id="text-message"
        className={`text-14 md:text-16 font-normal`}
        data-test-id="headers:ribbon-banner-text"
        dangerouslySetInnerHTML={{
          __html: RibbonDisplayMessage(displayMessage.textMessage, displayMessage.textHyperLink || null),
        }}
        style={{ color: displayMessage.ribbonFontColour }}
      />
      {displayMessage.closeButton && (
        <div id="close-icon" className="absolute right-4">
          <img
            className="md:w-4 md:h-4 w-[14px] h-[14px] cursor-pointer"
            data-test-id="headers:ribbon-banner-close-icon"
            src={closeIcon}
            onClick={() => {
              setRibbonShow(false);
              setRibbonBannerInCategoryPage(false);
            }}
            onKeyDown={() => {
              setRibbonShow(false);
              setRibbonBannerInCategoryPage(false);
            }}
            alt="ribbon-banner-close-icon"
          />
        </div>
      )}
    </div>
  );
};

Ribbons.propTypes = {
  ribbonDisplayMessage: PropTypes.array,
  setRibbonShow: PropTypes.func,
  setRibbonBannerInCategoryPage: PropTypes.func,
};

const mapDispatchToProps = {
  setRibbonBannerInCategoryPage: actions.setRibbonBannerInCategoryPage,
};

export default connect(null, mapDispatchToProps)(Ribbons);
